<template>
  <div class="hotGoods">
    <section class="Title">
      <p class="TitleBorder"></p>
      <p class="TitleName">{{ $t.cStore.hotSale }}</p>
    </section>
    <van-row class="list">
      <van-col
        class="item"
        v-for="item in hotProducts"
        :key="item.ProductNumber"
        @click="getProductDetails(item.ProductNumber)"
      >
        <figure class="goodsImg">
          <img
            class="goodsImgIcon"
            :src="
              item.ProductPhoto && imgUrlFilter(item.ProductPhoto.split('|')[0])
            "
          />
        </figure>
        <p class="itemDesc">{{ item.ProductName }}</p>
        <p class="itemSales">{{ item.Sales }}{{ $t.cStore.sold }}</p>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);

export default {
  name: "HotGoods",
  props: ["hotProducts"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    //跳转详情页
    getProductDetails(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "productNumber",
            value: ProductNumber
          }
        ]
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.hotGoods {
  width: 100%;
  padding: 24px 0;
  .Title {
    @include publicFlex;
    .TitleBorder {
      margin: 0;
      padding: 0;
      height: 10px;
      width: 30px;
      border-radius: 20px;
      background-color: #43cd9a;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .TitleName {
      margin: 0;
      padding: 0;
      font-size: 30px;
      color: #000000;
    }
  }
  .list {
    width: 100%;
    .item {
      width: 31%;
      margin: 20px 0;
      @include publicBoxRadio;
      background-color: #ffffff;
      &:nth-child(3n-1) {
        margin: 20px 3.5%;
      }
      .goodsImg {
        height: 202px;
        width: 100%;
        border-top-left-radius: 17px;
        border-top-right-radius: 17px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 18px;
        background-color: #313131;
        margin: 0;
        //padding: 0;
        .goodsImgIcon {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
        }
      }
      .itemDesc {
        margin: 0;
        padding: 0;
        @include spanNowrap;
        padding: 0 18px;
        box-sizing: border-box;
        line-height: 50px;
        height: 50px;
        -webkit-box-sizing: border-box;
        font-size: 20px;
        color: #000000;
      }
      .itemSales {
        margin: 0;
        padding: 0;
        padding: 0 18px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        font-size: 18px;
        padding-bottom: 18px;
        color: #a7a7a7;
      }
    }
  }
}
</style>
